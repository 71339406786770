:root {
    --mainColor: #0da487;
    --mainColor2: linear-gradient(90.56deg, var(--mainColor) 8.46%, var(--mainColor) 62.97%);
    --footerColor: #f8f8f8;
    --headerColor: #fff;
    --menuColor: #fff;
    --bodyColor: #fff;
    --cardColor: #F4F4F4;
    --productCardColor: #fff;
    --categoryColor: #fff9d8;
    --partnersColor: #f8f8f8;
    --reviewsColor: #f8f8f8;
    --bonusColor: var(--mainColor);
    --presentColor: #9C4FFF;
}

@import "./scss/style";
@import "./scss/widgets";

.tab-style-color{
    padding-bottom: 4px;
    &::-webkit-scrollbar {
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
    }
    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 4px;
        border: 1px solid #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #000000;
    }
}
.btn-border{
    border: 1px solid var(--mainColor);
    color: var(--mainColor);

    &:hover{
        border: 1px solid var(--mainColor);
        color: var(--mainColor);
    }
}
.map-container{
    background: #D9D9D9;
    border-radius: 0px 16px 16px 0px;

    width: 100%;
    height: 450px;
    .leaflet-container{
        height: 450px;
        width: 100%;
    }
}

@media(max-width: 767px){
    .map-container{
        height: 250px;
        margin-bottom: 20px;
        .leaflet-container{
            height: 250px;
        }
    }
    .mob-column-reverse{
        flex-direction: column-reverse;
    }
    .modal-dialog{
        padding-bottom: 60px;
    }
}
.category-list{
    padding-bottom: 4px;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
    }
    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 4px;
        border: 1px solid #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #000000;
    }
}
#root{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow-x: hidden;
}
.bg-img-abs{
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.bg-img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mr-3{
    margin-right: 10px;
}

.modal-header{
    border-bottom: none;
}

.product-wrapper2{
    display: flex;
    margin-bottom: 20px;
    img{
        height: 150px;
        width: 150px;
        object-fit: contain;
        margin-right: 12px;
    }

    h4{
        font-size: 20px;
        color: #222;
        font-weight: 600;
    }
    .price{
        color: var(--mainColor);
        font-weight: 600;
        font-size: 18px;
        margin-top: 10px;
    }
}

.note-box{
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
}

.slick-track
{
    display: flex !important;
}

.slick-slide{
    height: inherit !important;

    div{
        height: 100%;

        div{
            height: auto;
        }
    }
}

.title-sidebar{
    font-weight: 700;
}

.form-modal{
    h3{
        font-weight: 600;
        font-size: 32px;
        margin-bottom: 15px;
    }
    p{
        font-size: 16px;
        margin-bottom: 20px;
    }
    label{
        margin-bottom: 20px;
    }
}
.user-icon{
    cursor: pointer;
}
.preloader{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.432);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.isLoading {
    pointer-events: none;
    opacity: .5;
    position: relative;
    min-height: 100px;
    width: 100%;
    height: 100%;
  
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid var(--mainColor);
      border-left-color: transparent;
      animation: rotate .5s infinite linear;
      z-index: 99;
    }
  
    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
}
.form-loading{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    justify-content: center;
    display: flex;
    align-items: center;
    left: 0;
    background-color: #ffffff70;
    z-index: 1;
}
.close_button{
    font-weight: 600;
    cursor: pointer;
    background: #FFD7D7;
    color: #BF2020;
    padding: 8px;
    border-radius: 4px;
    width: fit-content;
}

@media(max-width: 767px){
    .header-2 .header-nav-left .category-dropdown{
        overflow-y: auto;
        padding-bottom: 30px;
        z-index: 11;
    }
}

.middle-box{
    position: relative;
}
.dropdown-search{
    position: absolute;
    background-color: #fff;
    width: 100%;
    top: 45px;
    z-index: 2;
    max-height: 265px;
    overflow-y: auto;
    display: none;
    padding: 4px 0;

    &-product{
        padding: 8px 28px 8px 18px;
        display: flex;

        &:hover{
            background-color: rgba(0,0,0,.03);
        }

        .title{
            font-size: 16px;
        }
        img{
            width: 44px;
            height: 44px;
            object-fit: contain;
            margin-right: 12px;
        }

        .price{
            margin-left: auto;
            font-weight: 600;
            font-size: 16px;
            white-space: nowrap;
        }
    }
}

.dropdown-visible{
    .dropdown-search{
        display: block;
    }
}

.heart-svg{
    cursor: pointer;
    &:hover{
        path{
            fill: var(--mainColor);
            stroke: var(--mainColor);
        }        
    }
}
.heart-success{
    path{
        fill: var(--mainColor);
        stroke: var(--mainColor);
    }
}

@media(max-width: 1400px){
    .product-section .product-left-box{
        width: 100%;
    }
}

@media(max-width: 767px){
    .product-section .product-left-box{
        width: 100%;
    }
    .product-section .product-main .slider-image{
        height: 300px;
    }
}

.buy-box{
    cursor: pointer;
    &:hover{
        path{
            fill: var(--mainColor);
            stroke: var(--mainColor);
        }
    }
}
.wishlist-product{
    .product-box-3{
        background-color: #fff;
    }
    .add-to-cart-box{
        background-color: #f8f8f8 !important;
    }
    .add-to-cart-box .btn-add-cart .add-icon.bg-light-gray{
        background-color: #fff;
    }
}
.form-success{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: .3s;

    i{
        font-size: 40px;
        color: #31D84F;
    }
}
.form-visible{
    opacity: 1;
    visibility: visible;
}
.theme-option-widgets{
    bottom: 83px;
    right: 30px;
}

@media(max-width: 767px){
    .theme-option-widgets{
        bottom: 138px;
    }
}
.nav-number{
    svg, path{
        fill: var(--mainColor);
    }
}
.success-address{
    display: flex;
    align-items: center;
    i{
        color: #0da487;
        margin-right: 4px;
    }
}

.edit-link{
    color: var(--mainColor);
    cursor: pointer;
    font-size: 14px;
}

.link-profile{
    color: var(--mainColor);
    cursor: pointer;

    span{
        font-weight: 600;
        margin-left: 15px;
    }
}

.order-image{
    img{
        width: 184px;
        height: 145px;
        object-fit: contain;
    }
}
.price{
    white-space: nowrap;
}
.red{
    color: #ff6b6b !important;
}
.min-price{
    margin-left: 10px;
    font-size: 14px;
}
.cart-table table tbody tr td.product-detail .product .product-detail ul{
    min-width: 300px;
}
.cart-table table tbody tr td.product-detail .product .product-detail ul li.name a{
    white-space: normal;
}
.cart-table{
    &::-webkit-scrollbar {
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
    }
    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 4px;
        border: 1px solid #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #000000;
    }
}
.cart-list{
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
    }
    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border: 1px solid #f1f1f1;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #000000;
    }
}
.note{
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    padding: 4px 0;
    color: #4a5568;
}
.button-icons{
    i{
        margin-right: 4px;
    }
}

.delete-link{
    color: #ff6b6b;
    cursor: pointer;
    transition: .3s;

    &:hover{
        color: #f34a4a;
    }
}

.edit-link{
    color: var(--mainColor);
    cursor: pointer;
    transition: .3s;

    &:hover{
        color: var(--mainColor);
    }
}
.product-order-text{
    background-color: #f8f8f8;
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 30px;
    border-radius: 8px;

    &.padding{
        padding: 0 calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    }
    .text-product{
        font-size: 19px;
        color: #222;

        span{
            color: #A3A3A3;
            font-size: 16px;
        }
    }

    .date{
        color: #8A8A8A;
        border-bottom: 1px solid #ECECEC;
        margin-bottom: 10px;
        padding-bottom: 4px;
        font-weight: 500;
    }
}

.user-dashboard-section .dashboard-right-sidebar .dashboard-order .order-contain .order-box{
    transition: .3s;
    border: 1px solid #fff;
    cursor: pointer;
    color: #000000;
    &:hover{
        border: 1px solid var(--mainColor);
        transform: scale(1.02);
        color: #000000;
    }
}
.address-icons{
    i:first-child{
        margin-right: 6px;
    }
    i{
        cursor: pointer;
    }
    .fa-edit{
        color: var(--mainColor);
    }
    .fa-trash{
        color: #f34a4a;
    }
}
.order-product{
    h4{
        color: #222;
        font-size: 19px;
        font-weight: 600;
    }
    .product-order-text{
        background-color: transparent;
        margin-top: 10px;
    }
    .icon-back{
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        margin-bottom: 20px;
        transition: .3s;
        i{
            margin-right: 4px;
        }

        &:hover{
            color: var(--mainColor);
            transform: translateX(-10px);
       
        }
    }
    .order-wrapper{
        padding: 24px;
        background-color: #fff;
        border-radius: 8px;
    }
}
.order-detail{
    .text-content{
        font-size: 16px;
        margin-top: 20px;
    }
}

.modal-lg{
    max-width: 650px;
}
.add-cart-item{
    margin-top: 20px;
    padding: 8px 16px;
    width: fit-content;
    border-radius: 3px;
    color: #9B9B9B;
    background: linear-gradient(188deg, #FAFAFA 5.52%, #F8F8F8 94.00%), #ECECEC;

    i{
        margin-right: 10px;
        color: var(--mainColor);
    }
}

.slick-list{
    .slick-active {
        z-index: 10;
    }
}
.partners-item{
    img{
        width: 120px;
        height: 120px;
        object-fit: contain;
    }
}
.partners{
    padding: 50px 0;
    background-color: var(--partnersColor);
}

.offer-card{
    background-color: var(--cardColor);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover{
        img{
            transform: scale(1.1);
        }
    }
    
    img{
        width: 100%;
        height: 300px;
        object-fit: contain;
        transition: .3s;
    }

    .btn-cart{
        position: absolute;
        bottom: 18px;
        right: 18px;
    }

    .offer-card-label{
        position: absolute;
        border-radius: 5px 30px 30px 5px;
        background: rgba(255, 255, 255, 0.95);
        box-shadow: 0px 3px 4px 0px rgba(34, 34, 34, 0.14);
        padding: 10px 15px;
        top: 17px;
        left: 18px;
        color: var(--mainColor);
        font-size: 16px;
        z-index: 1;
    }
}


@media(max-width: 767px){
    .fresh-vegetable-section .fresh-image-2 img{
        height: 300px;
    }
}

@media(max-width: 767px){
    .product-wrapper2{
        flex-direction: column;
        padding-bottom: 40px;
    }
    .product-wrapper2 .price{
        margin-bottom: 20px !important;
    }
    .modal-body{
        .product-wrapper2{
            padding-bottom: 0;
        }
        .btn-animation{
            width: 100%;
        }
    }
    .product-title h4{
        font-size: 16px;
    }
}
.shop-section .category-box h5{
    -webkit-line-clamp: 2;
}
.slider-9{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 16px;
}

@media(max-width: 1440px){
    .slider-9{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

@media(max-width: 1200px){
    .shop-section .slider-9{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media(max-width: 1024px){
    .slider-9{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

@media(max-width: 767px){
    .slider-9, .shop-section .slider-9{
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media(max-width: 480px){
    .slider-9, .shop-section .slider-9{
        grid-template-columns: 1fr 1fr;
    }
    .category-box h5{
        -webkit-line-clamp: 3;
    }
}

.form-check{
    .form-check-button{
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        border-radius: 50%;
        justify-content: center;
        background-color: #f7f6f4;
        font-size: 10px;
        cursor: pointer;
    }
}
.category-list{
    .filter-droupdowns{
        margin-left: 20px;

        .form-check .form-check-label{
            .name{
                color: #777777;
            }
        }
    }
}

.offer-banner{
    img{
        height: 380px;
    }
}

@media(max-width: 767px){
    .offer-banner img{
        height: 300px;
    }
}

.banners-height{
    height: 100%;

    .banner-itm{
        height: calc(50% - 0.5rem);
    }

    .home-contain{
        height: 100%;

    }
}
.header-2 .rightside-menu .dropdown-dollar .dropdown .dropdown-toggle {
    .lang-color{
        color: #000000;
    }
}
.height-100{
    height: 100%;
}
.isLoading-mini{
    min-height: auto;
    height: auto;
}
.product-section .right-box-contain .offer-bonus{
    background: #11951724;
    color: var(--bonusColor);
}
.product-section .right-box-contain .offer-present{
    background: rgba(156, 79, 255, 0.10);
    color: var(--presentColor);

    i{
        margin-left: 4px;
    }
}
.cart-bonus{
    position: absolute;
    z-index: 1;
    background-color: var(--bonusColor);
    color: #fff;
    top: 0;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 13px;

    svg{
        width: 8px;
        height: 14px;
        position: relative;
        top: -1px;
        margin-left: 2px;
    }
}

.checkout-section-2 .right-side-summery-box .summery-box-2 .summery-total li h4 .price-bonus, .summery-box .summery-total li h4 .price-bonus{
    background-color: var(--bonusColor);
    color: #fff;
    padding: 2px 8px;
    border-radius: 4px;
    font-weight: 400;
    margin-bottom: 4px;

    svg{
        width: 8px;
        height: 14px;
        position: relative;
        top: -1px;
        margin-left: 2px;
    }
}

.reset-btn{
    cursor: pointer;
    color: var(--mainColor);
}
.mt-6{
    margin-top: 6px;
}

.dropdown-menu .dropdown-item{
    white-space: normal;
}

.new-arrow-slider{
    .slick-next, .slick-prev{
        height: 100%;
        z-index: 1;
    }
    .slick-next{
        right: 0;
    }
    .slick-prev{
        left: 0;
    }
    .slick-prev:before, .slick-next:before{
        color: var(--mainColor);
        font-size: 29px;
    }
}

.new-arrow-slider2{
    .slick-next, .slick-prev{
        height: 100%;
        z-index: 1;
    }
    .slick-next{
        right: -30px;
    }
    .slick-prev{
        left: -30px;
    }
    .slick-prev:before, .slick-next:before{
        color: var(--mainColor);
        font-size: 29px;
    }
}
.summery-box-2 .checkout-slider .noUi-target{
    width:100%;
}
@media(max-height: 620px){
    .theme-modal .modal-dialog .modal-content .modal-footer{
        margin-bottom: 64px;
    }
}
.addcart-button{
    background-color: #D9D9D9;
    color: #7D7D7D;

    span{
        margin-left: 6px;
    }

    &:hover{
        background-color: #D9D9D9;
        color: #7D7D7D;
    }
}

.back-button2{
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: -50px;
    cursor: pointer;
    width: fit-content;

    span{
        background:var(--mainColor);
        border-radius: 6px;
        padding: 4px 6px;
        color: #fff;
    }
    i{
        margin-right: 4px;
    }
}

@media(max-width: 767px){
    .back-button2{
        margin-top: 0;
    }
}

#send-content{
    overflow: auto;
    max-height: 100%;
}
.free-delivery{
    margin-top: -8px;
    color: #959595;
}
.delivery-price{
    white-space: nowrap;
    font-weight: 600;
}
@media(max-width: 767px){
    .home-section-small .home-contain .home-detail.home-width .home-button{
        display: none;
    }
    .product-box-contain{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    .cart-table table tbody tr td.product-detail{
        grid-column: 1/3;
    }
    .cart-table table tbody tr td.product-detail .product .product-detail ul{
        min-width: auto;
    }
    .cart-table table tbody tr td{
        padding: 6px 0 !important;
        display: flex;
        align-items: center;
        border: none;
    }
    .cart-table table tbody tr{
        margin-bottom: 20px;
        background: #f8f8f8;
        padding: 18px 12px;
        border: none;
    }
    .cart-table table tbody tr td.price{
        h6{
            display: none;
        }

    }
    .cart-table{
        padding: 0;
        background: none;
    }
    .cart-table table tbody tr td .table-title{
        display: none;
    }
    .cart-table table tbody tr td.save-remove{
        grid-column: 1;
        grid-row: 3;
    }
    .cart-table table tbody tr td.quantity{
        justify-self: end;
    }
    .cart-table table tbody tr td.subtotal{
        justify-content: end;
    }
    .cart-table table tbody tr td.subtotal .text-content{
        display: block;
        margin: 0 6px 0;
        color: #777777;
    }
}
.title-flex-2{
    .new-title{
        white-space: nowrap;
        margin-right: 20px;
    }
}
.product-present{
    border-radius: 12px;
    border: 1px dashed var(--presentColor);
    background: rgba(156, 79, 255, 0.10);
    padding: 8px;
    margin-top: 18px;
    display: flex;
    gap: 16px;
    align-items: center;
    color: #222;

    img{
        height: 84px;
        width: 84px;
        min-width: 84px;
        object-fit: cover;
        border-radius: 4px;
    }
    .product-present-title{
        color: var(--presentColor);
    }
}
.present-mini{
    border-radius: 13px;
    border: 1px dashed var(--presentColor);
    background: #F0E8F9;
    color: var(--presentColor);
    padding: 8px;
    display: inline-block;

    span{
        color: #222;
    }
}
.additional-product{
    border-radius: 0px 0px 8px 8px;
    border: 1px dashed var(--presentColor);
    background: rgba(156, 79, 255, 0.10);
    padding: 4px;
    width: 100%;
    gap: 16px;
    display: flex;

    img{
        width: 43px;
        height: 43px;
        min-width: 43px;
        border-radius: 4px;
    }
    .color{
        color: var(--presentColor);
    }
}
.text-present{
    font-weight: 500;
    color: var(--presentColor);
    font-size: 16px;
}
.cart-table table tbody .product-present-tr{
    background: rgba(156, 79, 255, 0.10);
    td{
        padding: 8px;
    }
}